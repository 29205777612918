.jodit_vable_theme .jodit_toolbar {
  background: #f4bd5b;
  border: none;
}
.jodit_vable_theme .jodit_statusbar {
  background: #f4bd5b;
  border: none;
}

.jodit_placeholder {
  font-family: 'Montserrat', sans-serif;
  color: #777777;
}

.jodit_toolbar_btn-separator {
  border-right: 1px solid #777777 !important;
}

.jodit_tooltip {
  display: none;
}

.jodit_tooltip.jodit_tooltip_visible {
  display: initial;
}
