.navBarContainer {
  position: relative;
  z-index: 999;
  height: 6rem;
}

.menuContainer {
  z-index: 998;
  background: #fff0b0;
  position: fixed;
  width: 100%;
  height: 6rem;
}

.logoLink {
  height: 100%;
  display: inline-block;
}

.mainLogo {
  width: 3.2rem;
  display: inline-block;
  margin-left: 2rem;
  vertical-align: -0.8rem;
}

.navName {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 3.2rem;
  display: inline-block;
  letter-spacing: -0.1rem;
  margin-left: 0.7rem;
  font-style: none;
  color: black;
  padding-right: 1rem;
}

@media only screen and (min-width: 1024px) {
  .navBarContainer {
    padding-right: calc(49vw - 42rem);
    padding-left: calc(49vw - 42rem);
    z-index: initial;
  }

  .mainLogo {
    margin-left: 0;
  }
}
