@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300&family=Oswald&family=Quicksand:wght@500;600;700&display=swap);
.app {
  min-height: 100vh;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 300;
}

p {
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.appMain {
  padding: 7rem 2.5rem 18rem;
  margin: 0 auto;
}

button,
input[type='submit'] {
  height: 3.6rem;
  cursor: pointer;
  background-color: #f4bd5b;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  outline: none;
  border-radius: 1.4rem;
  padding: 0 2rem 0.2rem;
  transition: all 0.1s ease-out 0.1s;
  -webkit-transition: all 0.1s ease-out 0.1s;
  -moz-transition: all 0.1s ease-out 0.1s;
  -o-transition: all 0.1s ease-out 0.1s;
}

button:hover {
  color: white;
  background-color: #f0667b;
}

input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
  font-weight: 300;
}

input:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
  font-weight: 300;
}

input::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
  font-weight: 300;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
  font-weight: 300;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
  font-weight: 300;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
  font-weight: 300;
}

button:disabled,
button[disabled] {
  color: black;
}

.smallHeader {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: -0.5rem 0 0.5rem;
}

.pageHeader {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 3.6rem;
  color: black;
  padding: 1.7rem 0 0;
  margin: 0;
}

.subHeader {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 3.2rem;
  color: black;
  margin-bottom: 3rem;
  line-height: 1.3em;
}

.pageInstructions {
  padding: 0;
  margin-top: 1.7rem;
}

.textCenter {
  text-align: center;
}

.inlineBullet {
  margin: 0 0.1em;
  vertical-align: -0.08em;
}

.defaultMedium {
  font-weight: 500;
}

.underline {
  text-decoration: underline;
}

.inline {
  display: inline-block;
}

input[type='text'],
select,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-right: 0.3rem solid #f4bd5b;
  background-image: none;
  background-color: #fff0b0;
  box-shadow: none;
  padding-top: 0.2em;
  font-family: 'Montserrat', sans-serif;
}

.inputContainer {
  width: calc(100% - 4rem);
  height: auto;
  min-width: 27rem;
  min-height: 5rem;
  position: relative;
}

.validationError {
  color: red;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0.7rem 0 0.7rem 1rem;
}

@media only screen and (min-width: 768px) {
  .appMain {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .inputContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .pageHeader {
    font-size: 4.8rem;
  }
  .appMain {
    padding-right: calc(49vw - 42rem);
    padding-left: calc(49vw - 42rem);
  }
}

.footer {
  width: 100%;
  text-align: center;
  margin: 0;
  height: auto;
  position: absolute;
  bottom: 0;
  z-index: 3;
}

.footerInside {
  background: #fff0b0;
  height: 100%;
}

.socialImage {
  width: 4.5rem;
}

.socialContainer {
  display: flex;
  justify-content: space-evenly;
  padding-top: 2rem;
  max-width: 100rem;
  margin: 0 auto;
}

.footerWaveContainer {
  overflow: hidden;
  height: 2rem;
}

.footerWave {
  height: 2rem;
  object-position: -20rem;
}

.copyright {
  margin-top: 1em;
  padding-bottom: 1em;
  font-size: 1.4rem;
}

.portLink {
  color: black;
}

.oppBoardBanner {
  height: auto;
  margin: -8rem -2.5rem 0;
  padding: 8rem 2.5rem 0;
  background-color: #fff0b0;
  border-bottom: 0.2rem solid #f4bd5b;
}

.oppList {
  margin-bottom: 8rem;
}

.searchForm {
  position: relative;
  margin: 3.5rem 1rem 2rem;
}

.oppSearch[type='text'] {
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
  padding-left: 1rem;
  color: black;
  padding-bottom: 0.2em;
  background-color: white;
}

.searchButton {
  width: 4rem;
  height: 3.5rem;
  border-radius: 0;
  position: absolute;
  z-index: 1;
  right: 0;
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: center;
  background-image: url('/assets/mag-glass.svg');
}

.searchButton:hover {
  background-color: #f0667b;
}

.boardInstructions {
  text-align: center;
}

.opportunityCard {
  width: 100%;
  margin-top: 3.5rem;
}

.oppCardLink {
  background-color: #fff0b0;
  border-top: 0.2rem solid #f4bd5b;
  border-bottom: 0.2rem solid #f4bd5b;
  padding: 1rem;
  width: 100%;
  display: block;
  transition: all 0.5s;
}

.oppCardLink:hover {
  background-color: #f4bd5b;
}

.opportunityTitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 1.7rem;
  margin: 0.2rem 0 0.7rem;
}

.oppCardLink {
  color: black;
  text-decoration: none;
  font-size: 1.4rem;
}

.orgInfo,
.opportunityDescription {
  margin-bottom: 2rem;
}

.nothingFound {
  font-size: 3rem;
  font-weight: 200;
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 1rem;
}

.nothingLink {
  font-size: 1.8rem;
  font-weight: 300;
  color: black;
  text-align: center;
  display: block;
}

@media only screen and (min-width: 575px) {
  .oppList {
    padding: 0 8%;
  }

  .oppBoardBanner {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media only screen and (min-width: 768px) {
  .oppBoardBanner {
    margin-right: -4rem;
    margin-left: -4rem;
    padding-left: 25%;
    padding-right: 25%;
  }
}

@media only screen and (min-width: 1024px) {
  .oppBoardBanner {
    margin-right: calc(42rem - 49vw);
    margin-left: calc(42rem - 49vw);
    padding-left: calc(49vw - 27rem);
    padding-right: calc(49vw - 27rem);
  }

  .searchForm {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  .boardInstructions {
    padding: 0 10rem;
  }

  .oppList {
    margin-top: 5rem;
    padding: 0;
  }
}

.errorToast {
  position: fixed;
  top: 40vh;
  min-height: 6rem;
  left: 50%;
  margin: 0 auto;
  width: auto;
  border: 0.1rem solid gray;
  box-shadow: 1rem 1rem 0.5rem grey;
  background-image: url('/assets/alert.svg');
  background-repeat: no-repeat;
  background-size: 6rem;
  background-position: left;
  padding-left: 6rem;
  z-index: 998;
  background-color: #f0667b;
  font-size: 1.4rem;
  transform: translateX(-50%);
  -webkit-font-smoothing: antialiased;
}

.toastMessage {
  padding: 1rem 0 1rem 1rem;
  background: white;
  min-height: 6rem;
}

.toastButton {
  border-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 2.5rem;
  background-color: white;
  z-index: 0;
}

.toastButton:hover {
  background-color: white;
  color: #f0667b;
}

.hiddenToast {
  display: none;
}

.serverMessage {
  padding-right: 6rem;
}

.detailsHeader {
  margin-right: 25rem;
  margin-bottom: 2rem;
}

.detailsBanner {
  position: relative;
  height: 24rem;
}

.detailsInstructions {
  margin: 0 20rem 0 0;
}

.orgTitle {
  font-weight: 600;
}

.detailsImage {
  width: 17rem;
  position: absolute;
  right: 1rem;
  top: 2rem;
}

.detailsContainer {
  background-color: #fff0b0;
  padding: 2rem 2rem 1rem;
  width: auto;
  font-size: 1.4rem;
  margin-bottom: -6rem;
  padding-bottom: 5rem;
}

.detailsSection {
  position: relative;
  margin: 0 -2.5rem;
}

.detailsTitle {
  font-size: 2.2rem;
  margin: 0 0 1rem;
  font-weight: 700;
}

.opportunityInfo {
  margin-bottom: 1.3rem;
}

.volunteerLink {
  text-decoration: none;
  color: white;
}

.volunteerButton {
  width: auto;
  display: inline-block;
  height: 3.6rem;
  cursor: pointer;
  background-color: #f0667b;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  cursor: pointer;
  border-radius: 1.4rem;
  padding: 0.7rem 2rem 0;
  transition: all 0.3s;
  margin-bottom: 2rem;
}

.volunteerButton:hover {
  color: black;
  background-color: #f4bd5b;
}

.opportunityDescription {
  margin-top: 1rem;
}

.loadingTitle {
  font-size: 4rem;
  font-weight: 400;
  text-align: center;
  margin-top: 40vh;
}

.loadingOpportunity {
  text-align: center;
}

.spinnerContainer {
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 360px) {
  .detailsInstructions {
    margin: 0 16rem 0 0;
  }

  .detailsImage {
    right: 0;
  }
}

@media only screen and (min-width: 420px) {
  .detailsBanner {
    margin-right: 10%;
    margin-left: 10%;
  }
}

@media only screen and (min-width: 575px) {
  .detailsContainer {
    border-top: 0.2rem solid #f4bd5b;
    border-bottom: 0.2rem solid #f4bd5b;
    margin-bottom: 7rem;
    padding-bottom: 0;
    margin-left: 10%;
    margin-right: 10%;
  }

  .detailsImage {
    width: 22rem;
  }

  .detailsBanner {
    margin-right: 15%;
    margin-left: 15%;
    margin-bottom: 5rem;
  }

  .detailsInstructions {
    margin-right: 28rem;
  }

  .detailsHeader {
    margin-bottom: 2rem;
    line-height: 6rem;
    font-size: 4.8rem;
  }
}

@media only screen and (min-width: 768px) {
  .detailsBanner {
    margin-right: 20%;
    margin-left: 20%;
    margin-bottom: 5rem;
  }

  .detailsInstructions {
    margin-right: 26rem;
  }

  .detailsHeader {
    margin-bottom: 4rem;
  }
}

@media only screen and (min-width: 1024px) {
  .detailsInstructions {
    margin-right: 26rem;
  }

  .detailsContainer {
    margin-left: 0;
    margin-right: 0;
  }

  .descriptionContainer {
    margin-left: 0;
    margin-right: 0;
  }
}

.jodit_vable_theme .jodit_toolbar {
  background: #f4bd5b;
  border: none;
}
.jodit_vable_theme .jodit_statusbar {
  background: #f4bd5b;
  border: none;
}

.jodit_placeholder {
  font-family: 'Montserrat', sans-serif;
  color: #777777;
}

.jodit_toolbar_btn-separator {
  border-right: 1px solid #777777 !important;
}

.jodit_tooltip {
  display: none;
}

.jodit_tooltip.jodit_tooltip_visible {
  display: inline;
  display: initial;
}

.postInput {
  width: 100%;
  height: 3rem;
  font-size: 1.7rem;
  padding-left: 1rem;
}

.signupForm {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.postButton {
  margin-top: 2rem;
  margin-right: auto;
}

.editorContainer {
  margin: 2rem -2.5rem 0;
}

.postButtonContainer {
  text-align: center;
}

.dropdownButton {
  width: 4rem;
  height: 3rem;
  border-radius: 0;
  position: absolute;
  z-index: 1;
  right: 0;
}

.postSelect {
  border-right: 0;
  background-image: url('/assets/down-arrow.svg');
  background-repeat: no-repeat;
  background-size: 3rem;
  background-position: right;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  padding-top: 0.1em;
}

.loadingPost {
  width: 5.9rem;
  margin-top: 0.2rem;
}

.loadingPost > div {
  width: 1rem;
  height: 1rem;
}

@media only screen and (min-width: 520px) {
  .postInputs {
    padding-left: 10%;
    padding-right: 10%;
  }

  .oppPostBanner {
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .postInputs {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .oppPostBanner {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .postInputs {
    padding-right: 15rem;
    padding-left: 15rem;
  }
}

.landingBanner {
  margin: -8rem -2.5rem 5rem;
  padding: 8rem 2.5rem 0;
  height: 50rem;
  border-bottom: 0.1rem solid black;
  background: #fff0b0;
  position: relative;
}

.heroImage {
  width: 30.4rem;
  position: absolute;
  bottom: 0;
  right: 5%;
}

.actionImage {
  width: 16rem;
}

.actionVolContainer {
  margin-bottom: 6rem;
}

.featureSubHeader {
  padding-left: calc(50% - 16rem);
  margin-top: 0;
  position: relative;
  padding-top: 3rem;
}

.actionInstructions {
  padding: 0 2rem;
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.actionInstructionsVol {
  padding-top: 2rem;
}

.actionInstructionsOrg {
  padding-top: 2rem;
}

.actionLink {
  color: black;
  text-decoration: none;
}

.callToAction {
  margin-bottom: 8rem;
}

.appFeatures {
  margin: 0 -2.5rem;
  position: relative;
}

.bottomWaves {
  height: 2rem;
}

.topWaves {
  height: 2rem;
  object-position: -16rem;
}

.waveContainer {
  overflow: hidden;
  height: 2rem;
}

.featuresContainer {
  padding: 0 2.5rem;
  background: #fff0b0;
}

.handsImage {
  width: 14rem;
  position: absolute;
  top: -7rem;
  right: calc(50% - 11rem);
}

.features {
  display: inline-block;
}

.feature {
  width: calc(100vw - 10.5rem);
  display: inline-block;
  margin-bottom: 2.4rem;
}

.featureCheck {
  width: 3rem;
  margin-right: 1.5rem;
  vertical-align: 1rem;
}

.megaphoneImage {
  width: 8rem;
  display: inline-block;
  margin: 0 1.2rem 0 -2rem;
  vertical-align: -1rem;
}

.testimonialQuote {
  font-style: italic;
  margin-bottom: 1.5rem;
}

.testimonialAuthor {
  margin-bottom: 3.5rem;
}

.testimonials {
  margin-top: 2rem;
}

.featuresSubHeader {
  margin-top: 0;
}

@media only screen and (max-width: 360px) {
  .heroImage {
    width: 27rem;
  }

  .handsImage {
    width: 12rem;
    right: calc(50% - 6rem);
  }

  .featuresSubHeader {
    margin-top: 3rem;
    text-align: center;
    margin-bottom: 4rem;
  }
}

@media only screen and (min-width: 575px) {
  .landingBanner {
    padding-top: 24rem;
    margin-top: -18rem;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .heroTextContainer {
    left: 4rem;
    position: absolute;
  }

  .landingInstructions {
    width: calc(92vw - 33rem);
  }

  .heroImage {
    width: 30rem;
    right: 4rem;
  }
}

@media only screen and (min-width: 768px) {
  .heroImage {
    width: 35rem;
    right: 8%;
  }

  .heroTextContainer {
    left: 6rem;
    position: absolute;
  }

  .landingInstructions {
    width: calc(92vw - 42rem);
  }

  .landingBanner {
    padding-top: 24rem;
    margin-top: -18rem;
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .actionVolContainer {
    text-align: right;
    margin-right: 6rem;
    flex-basis: 100%;
  }

  .actionOrgContainer {
    text-align: left;
    margin-left: 6rem;
    flex-basis: 100%;
  }

  .actionSeparator {
    border-right: 0.2rem solid #f4bd5b;
    height: 50rem;
    align-self: center;
  }

  .callToAction {
    display: flex;
    margin-bottom: 4rem;
  }

  .actionInstructionsVol {
    padding-left: 5rem;
  }

  .actionInstructionsOrg {
    padding-right: 5rem;
  }

  .actionInstructions {
    padding: 0;
  }

  .handsImage {
    top: -7rem;
  }

  .appFeatures {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 3rem;
  }

  .featuresContainer {
    padding-right: calc(49vw - 33rem);
    padding-left: calc(49vw - 33rem);
  }

  .featureContainer {
    width: 31rem;
  }

  .feature {
    width: 26rem;
  }

  .featureCheck {
    vertical-align: 3rem;
  }

  .featureSubHeader {
    margin-left: calc(50% - 16rem);
    margin-bottom: 6rem;
    width: 32rem;
    padding-top: 3rem;
    padding-left: 0;
  }

  .testimonialsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .testimonial {
    width: 46%;
  }

  .testimonials {
    margin: 4rem 3rem 0;
  }
}

@media only screen and (min-width: 1024px) {
  .heroImage {
    width: 43rem;
    right: calc(49vw - 42rem);
  }

  .appFeatures {
    margin-left: calc(42rem - 49vw);
    margin-right: calc(42rem - 49vw);
  }

  .landingBanner {
    margin-right: calc(42rem - 49vw);
    margin-left: calc(42rem - 49vw);
    margin-top: -8rem;
    padding-top: 19rem;
  }

  .heroTextContainer {
    left: calc(49vw - 42rem);
    width: 42rem;
  }

  .landingInstructions {
    width: 42rem;
  }
}

.loginInput {
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
  padding-left: 1rem;
  color: black;
  padding-bottom: 0.2em;
}

.tallerInput {
  margin-bottom: 0.6rem;
}

.registerLink {
  font-weight: 600;
  color: black;
}

.loginForm {
  margin-top: 1.8rem;
  margin-bottom: 6rem;
}

.demoInstructions {
  margin: 5.5rem 0 3rem;
  line-height: 2.3rem;
}

.demoLogin {
  margin-bottom: 1rem;
}

.showLoginButton,
.hideLoginButton {
  width: 4rem;
  height: 3.5rem;
  border-radius: 0;
  position: absolute;
  z-index: 1;
  right: 0;
  background-repeat: no-repeat;
  background-size: 1.8rem;
  background-position: center;
}

.showLoginButton {
  background-image: url('/assets/show-password.svg');
}

.hideLoginButton {
  background-image: url('/assets/hide-password.svg');
}

.hideLoginButton:hover,
.showLoginButton:hover {
  background-color: #f0667b;
}

.loginButton {
  margin-top: 1rem;
}

.registerInstructions {
  margin-top: 2rem;
  font-size: 1.4rem;
}

.loadingLogin {
  width: 5.9rem;
  margin-top: 0.2rem;
}

.loadingLogin > div {
  width: 1rem;
  height: 1rem;
}

@media only screen and (min-width: 520px) {
  .loginFormContainer {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 768px) {
  .loginFormContainer {
    padding: 0 13rem;
  }
  .demoInstructions {
    margin-top: 5rem;
  }
  .registerInstructions {
    margin-top: 4rem;
  }
}

@media only screen and (min-width: 1024px) {
  .loginFormContainer {
    padding: 0 20rem;
  }
  .demoInstructions {
    margin-top: 5rem;
  }
  .registerInstructions {
    margin-top: 4rem;
  }
}

.navBarContainer {
  position: relative;
  z-index: 999;
  height: 6rem;
}

.menuContainer {
  z-index: 998;
  background: #fff0b0;
  position: fixed;
  width: 100%;
  height: 6rem;
}

.logoLink {
  height: 100%;
  display: inline-block;
}

.mainLogo {
  width: 3.2rem;
  display: inline-block;
  margin-left: 2rem;
  vertical-align: -0.8rem;
}

.navName {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 3.2rem;
  display: inline-block;
  letter-spacing: -0.1rem;
  margin-left: 0.7rem;
  font-style: none;
  color: black;
  padding-right: 1rem;
}

@media only screen and (min-width: 1024px) {
  .navBarContainer {
    padding-right: calc(49vw - 42rem);
    padding-left: calc(49vw - 42rem);
    z-index: auto;
    z-index: initial;
  }

  .mainLogo {
    margin-left: 0;
  }
}

.navMenu {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff0b0;
  justify-content: space-evenly;
  padding: 10vh 0;
  z-index: 1;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.navLink {
  color: black;
  text-decoration: none;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 3.2rem;
  margin: 0 2rem;
}

.navLink::after {
  content: '.';
}

.linkContainer {
  display: inline;
  position: static;
  margin: 0 auto;
  height: 6rem;
  border-bottom: 0.2rem solid #f4bd5b;
}

.linkBullet {
  margin: 0 0.08rem;
  font-size: 2em;
  line-height: 1rem;
  vertical-align: -0.3rem;
  margin: 0 1.3rem;
  display: none;
}

.burgerButton {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 1.9rem;
  right: 2rem;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.burgerButton span {
  display: block;
  position: absolute;
  height: 0.2rem;
  width: 100%;
  background: black;
  border-radius: 0.9rem;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.burgerButton span:nth-child(1) {
  top: 0;
  transform-origin: left center;
}

.burgerButton span:nth-child(2) {
  top: 0.9rem;
  transform-origin: left center;
}

.burgerButton span:nth-child(3) {
  top: 1.8rem;
  transform-origin: left center;
}

.trueBurger span:nth-child(1) {
  transform: rotate(45deg);
  left: 0.4rem;
}

.trueBurger span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.trueBurger span:nth-child(3) {
  transform: rotate(-45deg);
  left: 0.4rem;
}

.fadeMenuHidden {
  display: none;
}

@-webkit-keyframes fadeMenuDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeMenuDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeMenuDown {
  -webkit-animation-name: fadeMenuDown;
  animation-name: fadeMenuDown;
}

@-webkit-keyframes fadeMenuUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeMenuUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.fadeMenuUp {
  -webkit-animation-name: fadeMenuUp;
  animation-name: fadeMenuUp;
}

@media only screen and (max-width: 360px) {
  .navLink {
    font-size: 2.6rem;
  }
  .linkContainer {
    height: 4rem;
  }
}

@media only screen and (min-width: 1024px) {
  .navMenu {
    height: 6rem;
    overflow: hidden;
    padding: 0;
    margin-right: calc(49vw - 42rem);
    right: 0;
    left: auto;
    width: 70rem;
    display: inline-block;
    text-align: right;
    z-index: 999;
  }

  .navLink {
    font-size: 1.7rem;
    margin: 0;
    font-weight: 300;
    display: inline-block;
    height: 6rem;
    padding-top: 2rem;
  }

  .navLink::after {
    content: none;
  }

  .linkContainer {
    border-bottom: none;
  }

  .burgerButton {
    display: none;
  }

  .linkBullet {
    display: inline;
    display: initial;
  }

  @-webkit-keyframes fadeMenuUp {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  @keyframes fadeMenuUp {
    from {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeMenuUp {
    -webkit-animation-name: fadeMenuUp;
    animation-name: fadeMenuUp;
  }
}

.notFoundImage {
  width: 25rem;
  margin-top: 5rem;
}

.notFoundBanner h1 {
  line-height: 4rem;
}

.notFoundImageContainer {
  text-align: center;
  width: 100%;
}

.userTypeLink {
  text-decoration: none;
  color: black;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 3.2rem;
}

.userLinkContainer:first-child {
  margin-right: 2rem;
  margin-bottom: 4.5rem;
}

.userLinkContainer {
  border-bottom: 0.2rem solid #818282;
  width: auto;
  display: inline-block;
  padding-bottom: 0.6rem;
  margin-left: 1.5rem;
}

.checkpointArrow {
  width: 2rem;
  margin-left: 2rem;
}

.userTypeContainer {
  margin: 4.5rem -4rem 3rem;
  padding: 2rem 8rem 0 2.5rem;
  height: 20rem;
  background-image: url('/assets/checkpoint-background.png');
  background-repeat: repeat;
  background-position: center;
  background-size: auto 20rem;
}

@media only screen and (min-width: 375px) {
  .checkpointContainer {
    padding-left: 2.5rem;
  }
  .userTypeContainer {
    padding-left: 3.5rem;
    margin-left: -5rem;
    margin-right: -2.5rem;
  }

  .userLinkContainer:first-child {
    margin-right: 5rem;
    margin-bottom: 4.5rem;
  }
}

@media only screen and (min-width: 520px) {
  .userTypeContainer {
    padding-top: 7rem;
    padding-right: 0;
    padding-left: 5rem;
  }

  .checkpointBanner {
    padding-left: 1.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .checkpointBanner {
    padding-left: 0;
  }

  .userTypeContainer {
    margin-left: -6.5rem;
    margin-right: -4rem;
  }
}

@media only screen and (min-width: 1024px) {
  .checkpointContainer {
    padding-left: 0;
  }

  .userTypeContainer {
    margin-right: calc(42rem - 49vw);
    margin-left: calc(42rem - 49vw);
    padding-left: calc(49vw - 27rem);
    padding-right: calc(49vw - 40rem);
    padding-top: 5rem;
    margin-bottom: 7rem;
    margin-top: 6rem;
  }

  .userTypeLink {
    font-size: 4rem;
  }

  .userLinkContainer {
    margin-right: 1.5rem;
    padding-bottom: 2rem;
  }

  .checkpointBanner {
    padding-left: 15rem;
  }
  .userLinkContainer:first-child {
    margin-right: 8rem;
    margin-left: 0;
  }

  .checkpointArrow {
    width: 2.5rem;
  }
}

.registrationInput {
  width: 100%;
  height: 3rem;
  font-size: 1.7rem;
  padding-left: 1rem;
  padding-bottom: 0.3em;
}

.postForm {
  margin-top: 5rem;
  margin-bottom: 6rem;
}

.showRegistrationButton,
.hideRegistrationButton {
  width: 4rem;
  height: 3rem;
  border-radius: 0;
  position: absolute;
  z-index: 1;
  right: 0;
  background-repeat: no-repeat;
  background-size: 1.8rem;
  background-position: center;
}

.showRegistrationButton {
  background-image: url('/assets/show-password.svg');
}

.hideRegistrationButton {
  background-image: url('/assets/hide-password.svg');
}

.hideRegistrationButton:hover,
.showRegistrationButton:hover {
  background-color: #f0667b;
}

.registerButton {
  margin-top: 1rem;
}

.loadingRegistration {
  width: 5.9rem;
  margin-top: 0.2rem;
}

.loadingRegistration > div {
  width: 1rem;
  height: 1rem;
}

@media only screen and (min-width: 520px) {
  .registrationContainer {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 768px) {
  .registrationContainer {
    padding: 0 15%;
  }
}

@media only screen and (min-width: 1024px) {
  .registrationContainer {
    padding: 0 20rem;
  }

  .registerButton {
    margin-top: 2rem;
  }
}

.successImage {
  width: 25rem;
  margin-top: 7rem;
}

.successImageContainer {
  text-align: center;
}

.successBanner {
  position: relative;
}

.successInstructions {
  font-size: 2rem;
}

@media only screen and (min-width: 1024px) {
  .successBanner {
    margin-right: 20rem;
    margin-left: 20rem;
    text-align: center;
  }
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4 {
  font-family: 'Oswald', sans-serif;
}

