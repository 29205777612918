.app {
  min-height: 100vh;
  position: relative;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.7rem;
  font-weight: 300;
}

p {
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.appMain {
  padding: 7rem 2.5rem 18rem;
  margin: 0 auto;
}

button,
input[type='submit'] {
  height: 3.6rem;
  cursor: pointer;
  background-color: #f4bd5b;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  outline: none;
  border-radius: 1.4rem;
  padding: 0 2rem 0.2rem;
  transition: all 0.1s ease-out 0.1s;
  -webkit-transition: all 0.1s ease-out 0.1s;
  -moz-transition: all 0.1s ease-out 0.1s;
  -o-transition: all 0.1s ease-out 0.1s;
}

button:hover {
  color: white;
  background-color: #f0667b;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: black;
  opacity: 1; /* Firefox */
  font-weight: 300;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
  font-weight: 300;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
  font-weight: 300;
}

button:disabled,
button[disabled] {
  color: black;
}

.smallHeader {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  margin: -0.5rem 0 0.5rem;
}

.pageHeader {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 3.6rem;
  color: black;
  padding: 1.7rem 0 0;
  margin: 0;
}

.subHeader {
  font-family: 'Oswald', sans-serif;
  font-weight: 400;
  font-size: 3.2rem;
  color: black;
  margin-bottom: 3rem;
  line-height: 1.3em;
}

.pageInstructions {
  padding: 0;
  margin-top: 1.7rem;
}

.textCenter {
  text-align: center;
}

.inlineBullet {
  margin: 0 0.1em;
  vertical-align: -0.08em;
}

.defaultMedium {
  font-weight: 500;
}

.underline {
  text-decoration: underline;
}

.inline {
  display: inline-block;
}

input[type='text'],
select,
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-right: 0.3rem solid #f4bd5b;
  background-image: none;
  background-color: #fff0b0;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  padding-top: 0.2em;
  font-family: 'Montserrat', sans-serif;
}

.inputContainer {
  width: calc(100% - 4rem);
  height: auto;
  min-width: 27rem;
  min-height: 5rem;
  position: relative;
}

.validationError {
  color: red;
  font-size: 1.3rem;
  font-weight: 500;
  margin: 0.7rem 0 0.7rem 1rem;
}

@media only screen and (min-width: 768px) {
  .appMain {
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .inputContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) {
  .pageHeader {
    font-size: 4.8rem;
  }
  .appMain {
    padding-right: calc(49vw - 42rem);
    padding-left: calc(49vw - 42rem);
  }
}
