.postInput {
  width: 100%;
  height: 3rem;
  font-size: 1.7rem;
  padding-left: 1rem;
}

.signupForm {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.postButton {
  margin-top: 2rem;
  margin-right: auto;
}

.editorContainer {
  margin: 2rem -2.5rem 0;
}

.postButtonContainer {
  text-align: center;
}

.dropdownButton {
  width: 4rem;
  height: 3rem;
  border-radius: 0;
  position: absolute;
  z-index: 1;
  right: 0;
}

.postSelect {
  border-right: 0;
  background-image: url('/assets/down-arrow.svg');
  background-repeat: no-repeat;
  background-size: 3rem;
  background-position: right;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  padding-top: 0.1em;
}

.loadingPost {
  width: 5.9rem;
  margin-top: 0.2rem;
}

.loadingPost > div {
  width: 1rem;
  height: 1rem;
}

@media only screen and (min-width: 520px) {
  .postInputs {
    padding-left: 10%;
    padding-right: 10%;
  }

  .oppPostBanner {
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
  }
}

@media only screen and (min-width: 768px) {
  .postInputs {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .oppPostBanner {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .postInputs {
    padding-right: 15rem;
    padding-left: 15rem;
  }
}
