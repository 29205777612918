.landingBanner {
  margin: -8rem -2.5rem 5rem;
  padding: 8rem 2.5rem 0;
  height: 50rem;
  border-bottom: 0.1rem solid black;
  background: #fff0b0;
  position: relative;
}

.heroImage {
  width: 30.4rem;
  position: absolute;
  bottom: 0;
  right: 5%;
}

.actionImage {
  width: 16rem;
}

.actionVolContainer {
  margin-bottom: 6rem;
}

.featureSubHeader {
  padding-left: calc(50% - 16rem);
  margin-top: 0;
  position: relative;
  padding-top: 3rem;
}

.actionInstructions {
  padding: 0 2rem;
  font-size: 1.4rem;
  margin-bottom: 2rem;
}

.actionInstructionsVol {
  padding-top: 2rem;
}

.actionInstructionsOrg {
  padding-top: 2rem;
}

.actionLink {
  color: black;
  text-decoration: none;
}

.callToAction {
  margin-bottom: 8rem;
}

.appFeatures {
  margin: 0 -2.5rem;
  position: relative;
}

.bottomWaves {
  height: 2rem;
}

.topWaves {
  height: 2rem;
  object-position: -16rem;
}

.waveContainer {
  overflow: hidden;
  height: 2rem;
}

.featuresContainer {
  padding: 0 2.5rem;
  background: #fff0b0;
}

.handsImage {
  width: 14rem;
  position: absolute;
  top: -7rem;
  right: calc(50% - 11rem);
}

.features {
  display: inline-block;
}

.feature {
  width: calc(100vw - 10.5rem);
  display: inline-block;
  margin-bottom: 2.4rem;
}

.featureCheck {
  width: 3rem;
  margin-right: 1.5rem;
  vertical-align: 1rem;
}

.megaphoneImage {
  width: 8rem;
  display: inline-block;
  margin: 0 1.2rem 0 -2rem;
  vertical-align: -1rem;
}

.testimonialQuote {
  font-style: italic;
  margin-bottom: 1.5rem;
}

.testimonialAuthor {
  margin-bottom: 3.5rem;
}

.testimonials {
  margin-top: 2rem;
}

.featuresSubHeader {
  margin-top: 0;
}

@media only screen and (max-width: 360px) {
  .heroImage {
    width: 27rem;
  }

  .handsImage {
    width: 12rem;
    right: calc(50% - 6rem);
  }

  .featuresSubHeader {
    margin-top: 3rem;
    text-align: center;
    margin-bottom: 4rem;
  }
}

@media only screen and (min-width: 575px) {
  .landingBanner {
    padding-top: 24rem;
    margin-top: -18rem;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .heroTextContainer {
    left: 4rem;
    position: absolute;
  }

  .landingInstructions {
    width: calc(92vw - 33rem);
  }

  .heroImage {
    width: 30rem;
    right: 4rem;
  }
}

@media only screen and (min-width: 768px) {
  .heroImage {
    width: 35rem;
    right: 8%;
  }

  .heroTextContainer {
    left: 6rem;
    position: absolute;
  }

  .landingInstructions {
    width: calc(92vw - 42rem);
  }

  .landingBanner {
    padding-top: 24rem;
    margin-top: -18rem;
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .actionVolContainer {
    text-align: right;
    margin-right: 6rem;
    flex-basis: 100%;
  }

  .actionOrgContainer {
    text-align: left;
    margin-left: 6rem;
    flex-basis: 100%;
  }

  .actionSeparator {
    border-right: 0.2rem solid #f4bd5b;
    height: 50rem;
    align-self: center;
  }

  .callToAction {
    display: flex;
    margin-bottom: 4rem;
  }

  .actionInstructionsVol {
    padding-left: 5rem;
  }

  .actionInstructionsOrg {
    padding-right: 5rem;
  }

  .actionInstructions {
    padding: 0;
  }

  .handsImage {
    top: -7rem;
  }

  .appFeatures {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 3rem;
  }

  .featuresContainer {
    padding-right: calc(49vw - 33rem);
    padding-left: calc(49vw - 33rem);
  }

  .featureContainer {
    width: 31rem;
  }

  .feature {
    width: 26rem;
  }

  .featureCheck {
    vertical-align: 3rem;
  }

  .featureSubHeader {
    margin-left: calc(50% - 16rem);
    margin-bottom: 6rem;
    width: 32rem;
    padding-top: 3rem;
    padding-left: 0;
  }

  .testimonialsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }

  .testimonial {
    width: 46%;
  }

  .testimonials {
    margin: 4rem 3rem 0;
  }
}

@media only screen and (min-width: 1024px) {
  .heroImage {
    width: 43rem;
    right: calc(49vw - 42rem);
  }

  .appFeatures {
    margin-left: calc(42rem - 49vw);
    margin-right: calc(42rem - 49vw);
  }

  .landingBanner {
    margin-right: calc(42rem - 49vw);
    margin-left: calc(42rem - 49vw);
    margin-top: -8rem;
    padding-top: 19rem;
  }

  .heroTextContainer {
    left: calc(49vw - 42rem);
    width: 42rem;
  }

  .landingInstructions {
    width: 42rem;
  }
}
