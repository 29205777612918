.loginInput {
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
  padding-left: 1rem;
  color: black;
  padding-bottom: 0.2em;
}

.tallerInput {
  margin-bottom: 0.6rem;
}

.registerLink {
  font-weight: 600;
  color: black;
}

.loginForm {
  margin-top: 1.8rem;
  margin-bottom: 6rem;
}

.demoInstructions {
  margin: 5.5rem 0 3rem;
  line-height: 2.3rem;
}

.demoLogin {
  margin-bottom: 1rem;
}

.showLoginButton,
.hideLoginButton {
  width: 4rem;
  height: 3.5rem;
  border-radius: 0;
  position: absolute;
  z-index: 1;
  right: 0;
  background-repeat: no-repeat;
  background-size: 1.8rem;
  background-position: center;
}

.showLoginButton {
  background-image: url('/assets/show-password.svg');
}

.hideLoginButton {
  background-image: url('/assets/hide-password.svg');
}

.hideLoginButton:hover,
.showLoginButton:hover {
  background-color: #f0667b;
}

.loginButton {
  margin-top: 1rem;
}

.registerInstructions {
  margin-top: 2rem;
  font-size: 1.4rem;
}

.loadingLogin {
  width: 5.9rem;
  margin-top: 0.2rem;
}

.loadingLogin > div {
  width: 1rem;
  height: 1rem;
}

@media only screen and (min-width: 520px) {
  .loginFormContainer {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 768px) {
  .loginFormContainer {
    padding: 0 13rem;
  }
  .demoInstructions {
    margin-top: 5rem;
  }
  .registerInstructions {
    margin-top: 4rem;
  }
}

@media only screen and (min-width: 1024px) {
  .loginFormContainer {
    padding: 0 20rem;
  }
  .demoInstructions {
    margin-top: 5rem;
  }
  .registerInstructions {
    margin-top: 4rem;
  }
}
