.detailsHeader {
  margin-right: 25rem;
  margin-bottom: 2rem;
}

.detailsBanner {
  position: relative;
  height: 24rem;
}

.detailsInstructions {
  margin: 0 20rem 0 0;
}

.orgTitle {
  font-weight: 600;
}

.detailsImage {
  width: 17rem;
  position: absolute;
  right: 1rem;
  top: 2rem;
}

.detailsContainer {
  background-color: #fff0b0;
  padding: 2rem 2rem 1rem;
  width: auto;
  font-size: 1.4rem;
  margin-bottom: -6rem;
  padding-bottom: 5rem;
}

.detailsSection {
  position: relative;
  margin: 0 -2.5rem;
}

.detailsTitle {
  font-size: 2.2rem;
  margin: 0 0 1rem;
  font-weight: 700;
}

.opportunityInfo {
  margin-bottom: 1.3rem;
}

.volunteerLink {
  text-decoration: none;
  color: white;
}

.volunteerButton {
  width: auto;
  display: inline-block;
  height: 3.6rem;
  cursor: pointer;
  background-color: #f0667b;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.7rem;
  cursor: pointer;
  border-radius: 1.4rem;
  padding: 0.7rem 2rem 0;
  transition: all 0.3s;
  margin-bottom: 2rem;
}

.volunteerButton:hover {
  color: black;
  background-color: #f4bd5b;
}

.opportunityDescription {
  margin-top: 1rem;
}

.loadingTitle {
  font-size: 4rem;
  font-weight: 400;
  text-align: center;
  margin-top: 40vh;
}

.loadingOpportunity {
  text-align: center;
}

.spinnerContainer {
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.7);
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 360px) {
  .detailsInstructions {
    margin: 0 16rem 0 0;
  }

  .detailsImage {
    right: 0;
  }
}

@media only screen and (min-width: 420px) {
  .detailsBanner {
    margin-right: 10%;
    margin-left: 10%;
  }
}

@media only screen and (min-width: 575px) {
  .detailsContainer {
    border-top: 0.2rem solid #f4bd5b;
    border-bottom: 0.2rem solid #f4bd5b;
    margin-bottom: 7rem;
    padding-bottom: 0;
    margin-left: 10%;
    margin-right: 10%;
  }

  .detailsImage {
    width: 22rem;
  }

  .detailsBanner {
    margin-right: 15%;
    margin-left: 15%;
    margin-bottom: 5rem;
  }

  .detailsInstructions {
    margin-right: 28rem;
  }

  .detailsHeader {
    margin-bottom: 2rem;
    line-height: 6rem;
    font-size: 4.8rem;
  }
}

@media only screen and (min-width: 768px) {
  .detailsBanner {
    margin-right: 20%;
    margin-left: 20%;
    margin-bottom: 5rem;
  }

  .detailsInstructions {
    margin-right: 26rem;
  }

  .detailsHeader {
    margin-bottom: 4rem;
  }
}

@media only screen and (min-width: 1024px) {
  .detailsInstructions {
    margin-right: 26rem;
  }

  .detailsContainer {
    margin-left: 0;
    margin-right: 0;
  }

  .descriptionContainer {
    margin-left: 0;
    margin-right: 0;
  }
}
