.oppBoardBanner {
  height: auto;
  margin: -8rem -2.5rem 0;
  padding: 8rem 2.5rem 0;
  background-color: #fff0b0;
  border-bottom: 0.2rem solid #f4bd5b;
}

.oppList {
  margin-bottom: 8rem;
}

.searchForm {
  position: relative;
  margin: 3.5rem 1rem 2rem;
}

.oppSearch[type='text'] {
  width: 100%;
  height: 3.5rem;
  font-size: 1.7rem;
  padding-left: 1rem;
  color: black;
  padding-bottom: 0.2em;
  background-color: white;
}

.searchButton {
  width: 4rem;
  height: 3.5rem;
  border-radius: 0;
  position: absolute;
  z-index: 1;
  right: 0;
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: center;
  background-image: url('/assets/mag-glass.svg');
}

.searchButton:hover {
  background-color: #f0667b;
}

.boardInstructions {
  text-align: center;
}

.opportunityCard {
  width: 100%;
  margin-top: 3.5rem;
}

.oppCardLink {
  background-color: #fff0b0;
  border-top: 0.2rem solid #f4bd5b;
  border-bottom: 0.2rem solid #f4bd5b;
  padding: 1rem;
  width: 100%;
  display: block;
  transition: all 0.5s;
}

.oppCardLink:hover {
  background-color: #f4bd5b;
}

.opportunityTitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 1.7rem;
  margin: 0.2rem 0 0.7rem;
}

.oppCardLink {
  color: black;
  text-decoration: none;
  font-size: 1.4rem;
}

.orgInfo,
.opportunityDescription {
  margin-bottom: 2rem;
}

.nothingFound {
  font-size: 3rem;
  font-weight: 200;
  text-align: center;
  margin-top: 10rem;
  margin-bottom: 1rem;
}

.nothingLink {
  font-size: 1.8rem;
  font-weight: 300;
  color: black;
  text-align: center;
  display: block;
}

@media only screen and (min-width: 575px) {
  .oppList {
    padding: 0 8%;
  }

  .oppBoardBanner {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media only screen and (min-width: 768px) {
  .oppBoardBanner {
    margin-right: -4rem;
    margin-left: -4rem;
    padding-left: 25%;
    padding-right: 25%;
  }
}

@media only screen and (min-width: 1024px) {
  .oppBoardBanner {
    margin-right: calc(42rem - 49vw);
    margin-left: calc(42rem - 49vw);
    padding-left: calc(49vw - 27rem);
    padding-right: calc(49vw - 27rem);
  }

  .searchForm {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }

  .boardInstructions {
    padding: 0 10rem;
  }

  .oppList {
    margin-top: 5rem;
    padding: 0;
  }
}
