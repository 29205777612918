.footer {
  width: 100%;
  text-align: center;
  margin: 0;
  height: auto;
  position: absolute;
  bottom: 0;
  z-index: 3;
}

.footerInside {
  background: #fff0b0;
  height: 100%;
}

.socialImage {
  width: 4.5rem;
}

.socialContainer {
  display: flex;
  justify-content: space-evenly;
  padding-top: 2rem;
  max-width: 100rem;
  margin: 0 auto;
}

.footerWaveContainer {
  overflow: hidden;
  height: 2rem;
}

.footerWave {
  height: 2rem;
  object-position: -20rem;
}

.copyright {
  margin-top: 1em;
  padding-bottom: 1em;
  font-size: 1.4rem;
}

.portLink {
  color: black;
}
