.successImage {
  width: 25rem;
  margin-top: 7rem;
}

.successImageContainer {
  text-align: center;
}

.successBanner {
  position: relative;
}

.successInstructions {
  font-size: 2rem;
}

@media only screen and (min-width: 1024px) {
  .successBanner {
    margin-right: 20rem;
    margin-left: 20rem;
    text-align: center;
  }
}
