.registrationInput {
  width: 100%;
  height: 3rem;
  font-size: 1.7rem;
  padding-left: 1rem;
  padding-bottom: 0.3em;
}

.postForm {
  margin-top: 5rem;
  margin-bottom: 6rem;
}

.showRegistrationButton,
.hideRegistrationButton {
  width: 4rem;
  height: 3rem;
  border-radius: 0;
  position: absolute;
  z-index: 1;
  right: 0;
  background-repeat: no-repeat;
  background-size: 1.8rem;
  background-position: center;
}

.showRegistrationButton {
  background-image: url('/assets/show-password.svg');
}

.hideRegistrationButton {
  background-image: url('/assets/hide-password.svg');
}

.hideRegistrationButton:hover,
.showRegistrationButton:hover {
  background-color: #f0667b;
}

.registerButton {
  margin-top: 1rem;
}

.loadingRegistration {
  width: 5.9rem;
  margin-top: 0.2rem;
}

.loadingRegistration > div {
  width: 1rem;
  height: 1rem;
}

@media only screen and (min-width: 520px) {
  .registrationContainer {
    padding: 0 10%;
  }
}

@media only screen and (min-width: 768px) {
  .registrationContainer {
    padding: 0 15%;
  }
}

@media only screen and (min-width: 1024px) {
  .registrationContainer {
    padding: 0 20rem;
  }

  .registerButton {
    margin-top: 2rem;
  }
}
