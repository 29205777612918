.errorToast {
  position: fixed;
  top: 40vh;
  min-height: 6rem;
  left: 50%;
  margin: 0 auto;
  width: auto;
  border: 0.1rem solid gray;
  box-shadow: 1rem 1rem 0.5rem grey;
  background-image: url('/assets/alert.svg');
  background-repeat: no-repeat;
  background-size: 6rem;
  background-position: left;
  padding-left: 6rem;
  z-index: 998;
  background-color: #f0667b;
  font-size: 1.4rem;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-font-smoothing: antialiased;
}

.toastMessage {
  padding: 1rem 0 1rem 1rem;
  background: white;
  min-height: 6rem;
}

.toastButton {
  border-radius: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 2.5rem;
  background-color: white;
  z-index: 0;
}

.toastButton:hover {
  background-color: white;
  color: #f0667b;
}

.hiddenToast {
  display: none;
}

.serverMessage {
  padding-right: 6rem;
}
